body {
  cursor: auto;
  margin: 0px;
  padding: 0px;
  /* background: #000; */
  font-family: Arial, Helvetica, sans-serif;
  /* color: #fff; */
  font-family: "Mulish";
}

#quix-dashboard-wrapper,
#quix-dashboard-inner {
  float: left;
  width: 100%;
}

#quix-dashboard-header {
  float: left;
  width: 100%;
  padding: 15px 2% 8px 2%;
  font-size: 14px;
  /*  */
  background: #000;
}

div#quix-dashboard-sidebar {
  float: left;
  width: 100%;
  padding: 0 2%;
  display: flex;
  justify-content: center;
}

div#quix-dashboard-content {
  /* ,div#quix-profile-content */
  float: left;
  width: 100%;
  background: #fff;
  color: #000;
  font-size: 14px;
  padding: 30px 3%;
}

.quix-dashboard-logo {
  padding-left: 20px;
}

.quix-dashboard-left {
  float: left;
}

.quix-dashboard-right {
  position: relative;
  float: right;
}

.quix-dashboard-hamburger,
.quix-dashboard-logo {
  float: left;
}

.quix-dashboard-hamburger img {
  width: 25px;
}

.quix-dashboard-logo span {
  float: left;
  padding: 5px 10px;
  font-size: 16px;
}

.quix-dashboard-logo img {
  float: left;
  width: 25px;
}

.quix-dashboard-new-item-button,
.quix-dashboard-notification,
.quix-dashboard-user {
  float: left;
  margin: 0px 5px;
  cursor: pointer;
}

.quix-dashboard-new-item-button img,
.quix-dashboard-notification img,
.quix-dashboard-user img {
  float: left;
  width: 25px;
  border-radius: 100%;
}

.quix-dashboard-user img {
  height: 25px;
}

.quix-dashboard-new-item-button span {
  padding: 4px 5px 2px 5px;
  float: left;
}

.dashboard-button {
  background: #ffffff;
  padding: 5px;
  border-radius: 7px;
  cursor: pointer;
  color: #000000;
}

.quix-dashboard-new-item-button.dashboard-button {
  position: relative;
  top: 0px;
  background: #000000;
  color: #ffffff;
  border-radius: unset;
}

.quix-dashboard-sidebar-items {
  float: left;
  margin: 2px 0px;
  cursor: pointer;
  text-align: center;
  border-bottom: 2px solid transparent;
}

.quix-dashboard-sidebar-items.active {
  border-bottom: 2px solid #ffffff;
}

.quix-dashboard-sidebar-item {
  float: left;
  width: 100%;
}

.quix-dashboard-sidebar-items:hover {
  border-bottom: 2px solid #ffffff;
}

.quix-dashboard-sidebar-item img {
  width: 25px;
  float: left;
}

.quix-dashboard-sidebar-item span {
  float: left;
  padding: 5px 10px;
}

.quix-dashboard-middle {
  float: unset;
  margin: 0px auto;
  width: 400px;
}

.quix-dashboard-content-videos,
.quix-dashboard-content-images {
  float: left;
  width: 100%;
  margin: 10px 0 0 0;
}

.quix-dashboard-content-innr {
  display: grid;
  gap: 1rem;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
}

.quix-dashboard-content-header {
  float: left;
  padding: 20px 0%;
  width: 100%;
  border-bottom: 1px solid #dedede;
}

.quix-dashboard-content-header span {
  font-size: 18px;
  font-weight: 600;
  float: left;
}

/* .item-options-icon {
  display: none;
} */
/* .quix-dashboard-content-item-outer {
  width: 30%;
  float: left;
  margin: 10px 5px 10px 5px;
  position: relative;
  min-width: 300px;
  max-width: 300px;
  border-radius: 7px;
  padding: .5% .5%;
} */
.quix-multi-select input[type="checkbox"] {
  width: 18px;
  height: 18px;
  border: 1px solid #000;
  border-radius: 2px;
}

.quix-multi-select label {
  margin-top: 2px;
  margin-left: 5px;
  font-size: 14px;
  font-weight: 600;
}

.quix-dashboard-content-item-outer {
  width: 50%;
  float: left;
  margin: auto;
  position: relative;
  min-width: 300px;
  max-width: 300px;
  border-radius: 7px;
  padding: 0.5% 0.5%;
  height: 100%;
}

.quix-dashboard-content-item.item-not-available {
  border-bottom: 5px solid red;
}

.quix-dashboard-content-item.item-available {
  border-bottom: 5px solid green;
}

.quix-dashboard-content-item {
  width: 100%;
  float: left;
  border-radius: 10px;
  overflow: hidden;
}

.item-options-icon {
  position: absolute;
  top: 0;
  right: 0;
  padding: 10px 20px;
  display: none;
}

.item-options-icon>img {
  width: 30px;
  cursor: pointer;
  background-color: #00000075;
  padding: 5px;
  border-radius: 5px;
}

.quix-dashboard-content-item>img {
  width: 100%;
  cursor: pointer;
  float: left;
  object-fit: contain;
  height: 170px;
  background: #000;
}

.item-footer-inner .item-date {
  float: left;
  width: 100%;
  padding: 5px 0px;
}

.item-footer-inner .item-title {
  float: left;
  width: 90%;
  height: 32px;
  padding: 10px 0px 5px 0px;
  font-weight: 700;
  text-transform: capitalize;
  overflow: hidden;
  /* height: 15px; */
  margin-bottom: 5px;
}

.item-footer-inner .item-date img {
  width: 21px;
  float: left;
}

.item-footer-inner .item-date span {
  float: left;
  padding: 4px 2px;
  font-weight: 400;
  color: #000000;
}

.item-date label {
  float: left;
  padding: 2px 0px 2px 5px;
}

.item-footer-info,
.item-footer-inner {
  float: left;
  width: 100%;
  position: relative;
  white-space: pre-wrap;
  word-break: break-all;
}

.item-footer-info .quix-card-summary-dec {
  font-size: 13px;
  display: -webkit-inline-box;
}

.item-footer-info .quix-card-summary-dec p {
  display: -webkit-box;
  max-width: 100%;
  margin: 0 auto;
  font-size: 13px;
  line-height: 16px;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

/*  */
.starred-image-outer img {
  width: 18px !important;
}

span.videos-progress,
span.images-progress {
  background: #3f3e3e;
  padding: 0px !important;
  height: 5px;
  width: 55%;
  border-radius: 5px;
  margin-top: 12px;
  margin-left: 3%;
}

span.videos-progress-inner,
span.images-progress-inner {
  background: #fff;
  padding: 0px !important;
  height: 5px;
  width: 0%;
  border-radius: 5px;
}

select#quix-dashboard-actions,
select#quix-dashboard-sort-actions {
  padding: 9px 5px;
  background: #000;
  color: #fff;
  border: none;
  top: -5px;
  position: relative;
}

select#quix-dashboard-actions {
  margin-right: 5px;
}

.quix-dashboard-content-item:hover>.item-options-icon {
  display: block;
}

.item-options-grid {
  position: absolute;
  right: 15px;
  top: 30px;
  width: 190px;
  background: #fff;
  border-radius: 7px;
  padding: 10px;
  box-shadow: 0px 0px 5px #000000;
  color: #fff;
  z-index: 10;
}

.item-options-grid-row {
  float: left;
  width: 100%;
  margin: 4px 0px;
  padding: 2px 0px;
  cursor: pointer;
}

.item-options-grid-row img {
  width: 20px;
  float: left;
}

.item-options-grid-row span {
  float: left;
  margin: 2px 0px 0px 5px;
  color: #000;
}

.quix-dashboard-sidebar-items a {
  text-decoration: none;
  color: #fff;
  float: left;
}

.quix-dashboard-sidebar-items a:hover {
  text-decoration: none;
  color: #fff;
}

.access-denied-error {
  text-align: center;
  padding: 10%;
  font-size: 20px;
}

/* popup */
div#quix-dashboard-overlay,
div#quix-dashboard-overlay-upgrade {
  background: #000000;
  opacity: 0.5;
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 111;
  display: none;
}

div.quix-dashboard-upgrade-popup {
  display: none;
}

div#quix-dashboard-popup {
  float: left;
  width: 100%;
  text-align: center;
  z-index: 111;
  position: absolute;
  top: 10%;
  left: 0;
  /* display: none; */
}

div.quix-dashboard-popup-inner {
  background: #fff;
  width: 500px;
  display: inline-block;
}

.quix-dashboard-popup-footer-inner button {
  background: #000000;
  padding: 8px 10px;
  border-radius: 7px;
  cursor: pointer;
  border: none;
  margin: 5px;
  color: #ffffff;
}

.quix-dashboard-popup-title-inner span {
  font-size: 20px;
  font-weight: 600;
  color: #000;
  padding: 10px 0px;
  width: 100%;
  float: left;
}

.quix-dashboard-popup-body-inner input[type="text"],
.quix-dashboard-popup-body-inner textarea.form-control {
  padding: 5px 10px;
  border-radius: 5px;
  border: 1px solid #898383;
  width: 70%;
}

p.message-counter {
  text-align: left;
  font-size: 10px;
  color: red;
  height: 0px;
  position: relative;
  top: -5px;
  width: 100%;
  float: left;
  margin: 0px;
  padding-left: 13%;
}

.social-share-email .quix-dashboard-popup-body-inner {
  padding: 0 40px;
}

.social-share-email .quix-dashboard-popup-body-inner input,
.social-share-email .quix-dashboard-popup-body-inner textarea {
  width: 100%;
}

.quix-dashboard-popup-title,
.quix-dashboard-popup-body,
.quix-dashboard-popup-footer {
  float: left;
  width: 100%;
  margin: 10px 0px;
}

.quix-dashboard-content-item-outer input[type="checkbox"] {
  position: absolute;
  top: 10px;
  left: 10px;
  width: 18px;
  height: 18px;
  cursor: pointer;
}

.quix-dashboard-content-actions {
  position: absolute;
  right: 3%;
  padding: 16px 0px;
}

select#select-file-type {
  margin-right: 20px;
  padding: 5px;
}

button#login-with-gmail {
  border: 1px solid #000000;
  padding: 10px 30px;
  border-radius: 10px;
  font-size: 16px;
  font-weight: bold;
  margin-top: 20px;
  cursor: pointer;
}

.login-form-inner {
  float: left;
  width: 100%;
  text-align: center;
  margin-bottom: 50px;
}

.login-item span {
  float: left;
  width: 100%;
  padding-top: 5px;
  font-size: 14px;
  cursor: pointer;
  color: #000000;
}

.login-form-inner img {
  width: 200px;
  margin: 0px auto;
}

.login-form-outer {
  width: 500px;
  margin: 4% auto 0 auto;
  color: #000;
  padding: 20px;
}

span.form-title {
  width: 100%;
  text-align: center;
  float: left;
  font-size: 22px;
  margin-bottom: 5px;
  font-weight: 600;
}

span.form-subtitle {
  width: 100%;
  text-align: center;
  float: left;
}

.login-form-block {
  float: left;
  width: 90%;
  border-radius: 8px;
  padding: 10px 5% 5% 5%;
}

.login-form-title {
  float: left;
  width: 100%;
  margin-bottom: 30px;
}

.login-item {
  float: left;
  width: 25%;
  text-align: center;
}

.login-item img {
  width: 35px;
  cursor: pointer;
}

.loader-box {
  background: #2f51b5bd;
  height: 100%;
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1111;
}

.quix-dashboard-user-profile {
  position: absolute;
  right: 0px;
  top: 25px;
  background: #000;
  padding: 10px 20px;
  border-radius: 7px;
  z-index: 12222;
  width: 260px;
  color: #fff;
  display: none;
}

.quix-dashboard-user-profile>div {
  float: left;
  width: 100%;
}

.user-profile-name-outer span {
  float: left;
  font-size: 15px;
  padding: 2px 0px;
  width: 100%;
  overflow: hidden;
  height: 25px;
}

.user-profile-name-outer span:last-child {
  font-size: 13px;
  color: #727374;
}

.quix-dashboard-user:hover>.quix-dashboard-user-profile {
  display: block;
}

.quix-dashboard-user-profile-pic img {
  width: 40px;
  float: left;
  border-radius: 100%;
  height: 40px;
}

.user-profile-pic-outer {
  float: left;
  width: 25%;
}

.user-profile-name-outer {
  float: left;
  width: 75%;
}

.quix-dashboard-upgrade-button {
  padding: 10px;
  margin: 20px 0px 10px 0px;
  text-align: center;
}

.quix-dashboard-upgrade-button img {
  float: none;
  margin-right: 10px;
}

.quix-dashboard-upgrade-button span {
  position: relative;
}

a.user-profile-upgrade {
  text-decoration: none;
  color: #000;
}

.quix-dashboard-divider {
  height: 1px;
  border-top: 1px solid #fff;
  margin: 5px 0px;
}

.quix-dashboard-user-profile-links-row {
  float: left;
  width: 100%;
  margin: 5px 0px;
  cursor: pointer;
}

.quix-dashboard-user-profile-links-row span a {
  text-decoration: none;
  color: #fff;
}

.quix-dashboard-user-profile-progress-images {
  float: left;
  width: 100%;
  margin: 8px 0px;
}

.quix-dashboard-user-profile-progress-images img {
  float: left;
  padding: 2px 2px;
  border-radius: 0px;
}

.quix-dashboard-user-profile-progress-images span {
  float: left;
  padding: 5px;
}

.access-denied-error-button {
  margin-top: 50px;
  text-align: center;
}

.access-denied-error-button-inner {
  width: 100px;
  margin: 0px auto;
  background: #fff;
  padding: 10px 20px;
  border-radius: 7px;
  cursor: pointer;
  height: 30px;
}

.access-denied-error-button-inner img {
  width: 30px;
  float: left;
}

.access-denied-error-button-inner a {
  float: left;
  padding: 5px 10px;
  color: #000;
  text-decoration: none;
  font-size: 18px;
}

.no-records-outer,
.no-records-inner {
  float: left;
  padding: 10px;
  text-align: center;
  width: 100%;
}

.starred-image-outer {
  float: left;
  padding-left: 10px;
  position: absolute;
  top: 7px;
  right: 5px;
}

input#input-file-upload {
  color: #000;
}

.quix-dashboard-logo img:last-child {
  width: auto;
  padding: 0px 10px 6px 10px;
  height: 46px;
}

.quix-dashboard-content-header img {
  height: 5px;
  float: left;
  margin: 9px 10px 0px 10px;
}

#upload-custom-video-outer {
  display: none;
  width: 700px;
  height: 150px;
  background: #000;
  color: #ffffff;
  margin: 0px auto;
  position: relative;
  cursor: pointer;
}


#quix-upload-video .upload-loader {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  background: #000000;
  opacity: 0.5;
  display: flex;
  align-items: center;
  justify-content: center;
}

#quix-upload-video .upload-loader .video-loader-icon {
  height: 40px;
  width: 40px;
}

input#upload-custom-video {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  opacity: 0;
  cursor: pointer;
}

span.upload-custom-video-area {
  font-size: 16px;
  display: table-cell;
  vertical-align: middle;
  text-align: center;
}

#quix-upload-video {
  float: left;
  width: 100%;
  padding: 15% 0;
}

#quix-dashboard-loader,
#quix-dashboard-loader-overlay,
#quix-dashboard-loader-img {
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 11;
}

#quix-dashboard-loader {
  display: table;
  display: none;
}

#quix-dashboard-loader-overlay {
  background: #000;
  opacity: 0.7;
}

#quix-dashboard-loader-img {
  top: 40%;
  text-align: center;
  position: fixed;
}

#quix-dashboard-loader-img img {
  width: 65px;
}

.quix-dashboard-popup-body-inner span {
  font-size: 16px;
  color: #000;
}

/* Video Editor Page */
#video-editor-wrapper {
  float: left;
  width: 100%;
  color: #fff;
}

#video-editor-top {
  float: left;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px 4% 15px 4%;
}

#video-editor-top .quix-power-logo {
  font-size: 13px;
}

#video-editor-top .quix-power-logo img {
  width: auto;
  height: 26px;
}

/* #video-editor-content {
  float: left;
  width: 92%;
  padding: 0px 4%;
  text-align: center;
  position: relative;
} */
#video-editor-content {
  /* float: left; */
  width: 70%;
  text-align: center;
  position: relative;
  display: flex;
  justify-content: center;
  gap: 0 26px;
  margin: 0 auto;
}

#video-editor-bottom {
  float: left;
  width: 100%;
  padding: 0px 4%;
  margin: 0 auto;
}

#video-editor-back,
#video-editor-back span {
  float: left;
}

#video-editor-back a {
  color: #fff;
  text-decoration: none;
  position: relative;
  top: 2px;
}

#video-editor-back img {
  width: 25px;
}

span.video-editor-title {
  padding: 1px 10px;
  font-size: 16px;
}

div#video-editor-nav {
  float: right;
}

div#video-editor-nav img {
  width: 20px;
  float: left;
  margin: 7px 8px;
  cursor: pointer;
}

.editor-save-button {
  background: #ffffff;
  border: none;
  color: #000;
  border-radius: 7px;
  cursor: pointer;
  padding: 10px 20px;
  margin-left: 20px;
}

#video-editor-content video {
  width: 100%;
  background: #1f1f1f;
  border-radius: 7px;
  z-index: 10;
  position: relative;
}

#video-editor-content-box {
  position: relative;
}

/* quix-data-loader */
.quix-dashboard-content-innr.quix-data-loading {
  display: flex;
  justify-content: center;
  height: 100px;
  align-items: center;
}

/* video loader css */
#video-editor-content-box .video-loader {
  position: absolute;
  left: 50%;
  top: 50%;
  z-index: 111;
  transform: translate(-50%, -50%);
  display: none;
}

.video-editor-controls {
  float: left;
  width: 100%;
  text-align: center;
  padding: 15px 0px;
  position: relative;
  display: flex;
  justify-content: center;
}

.video-editor-controls img {
  width: 25px;
  margin: 0 5px;
}

#video-editor-editing {
  float: left;
  width: 100%;
  border: 1px solid #fff;
  border-radius: 7px;
  user-select: none;
  margin-bottom: 30px;
}

#video-editor-editing-toolbar {
  border: 1px solid #fff;
  border-radius: 7px;
  padding: 15px 10px 10px 10px;
}

#video-editor-editing-toolbar img {
  width: 18px;
  margin: 0px 12px;
}

#video-editor-editing-selection {
  background: #1f1f1f;
  float: left;
  width: 100%;
  border-bottom-right-radius: 7px;
  border-bottom-left-radius: 7px;
}

.item-options-grid-row a {
  color: #fff;
  text-decoration: none;
}

.video-editor-controls {
  cursor: pointer;
}

/* #video-editor-bottom {
  margin-bottom: 20px;
} */
div#video-editor-editing-selection-inner {
  float: left;
  width: 100%;
  height: 200px;
  position: relative;
}

div#video-progress-pointer {
  position: absolute;
  width: 3px;
  border-left: 1px solid #ffffff;
  height: 100%;
  border-right: 1px solid #ffffff;
  background: #ecec2b;
  cursor: pointer;
  left: 0;
  top: 0;
  z-index: 10;
}

div#video-progress-bar {
  position: absolute;
  bottom: 5px;
  left: 0;
  height: 30px;
  width: 100%;
}

div#video-progress-bar-inner {
  background: #565555;
  width: 99%;
  border-radius: 5px;
  margin: 3px 0.5% 2px 0.5%;
  height: 25px;
}

span.video-progress-start-time {
  float: left;
  margin-left: 5px;
  padding-top: 5px;
  font-size: 13px;
}

span.video-progress-end-time {
  float: right;
  margin-right: 5px;
  padding-top: 5px;
  font-size: 13px;
}

.video-progress-timer-outer {
  margin-top: 20px;
  position: absolute;
  top: 0;
  /* right: 14%; */
  right: 0;
}

#video-editor-content-inner>span {
  padding: 10% 0px;
  float: left;
  width: 100%;
  font-size: 25px;
}

span.video-progress-timer-now {
  padding-right: 1px;
}

#video-editor-editing-toolbar {
  padding: 5px 2% 0px 2%;
  float: left;
  width: 100%;
}

#video-editor-editing-toolbar span {
  float: left;
  padding: 8px 0px;
  cursor: pointer;
  padding-top: 0;
}

#video-editor-editing-toolbar span input {
  cursor: pointer;
}

#video-editor-editing-options-inner .video-editor-editing-options-col input {
  line-height: 100%;
}

div#quix-dashboard-popup {
  position: fixed;
}

.quix-dashboard-popup-body-inner input {
  color: #000;
}

.int-select-box {
  position: absolute;
  top: unset;
  left: 0px;
  background: #c5c4c4;
  height: 20px;
  min-width: 50px;
  width: max-content;
  border-top: 2px solid #fff;
  border-bottom: 2px solid #fff;
  border-left: 6px solid #fff;
  border-right: 6px solid #fff;
  border-radius: 5px;
}

.int-select-box img.int-select-box-icon {
  width: 16px;
}

.video-editor-pause {
  display: none;
}

.int-select-box-inner span {
  text-align: center;
  font-size: 11px;
  color: #000;
  /* width: 33%; */
  float: left;
  padding: 2px 0px;
}

/* div#video-editor-content-inner {
  position: relative;
  margin: 0px auto;
  width: 75%;
  max-width: 900px;
  min-width: 700px;
} */
div#video-editor-content-inner {
  position: relative;
  /* margin: 0px auto; */
  /* width: 75%; */
  max-width: 900px;
  min-width: 700px;
  overflow: hidden;
}

canvas#video-layer {
  position: absolute;
  left: 0;
  right: 0;
  height: 100%;
  width: 100%;
  z-index: 11;
}

input#upload-image,
input#upload-video,
input#input-upload-music,
input#upload-insert {
  float: left;
  width: 15px;
  position: absolute;
  left: 0;
  top: 0;
  opacity: 0;
  margin: 0 10px;
  cursor: pointer;
}

span.video-editing-image,
span.video-editing-video,
span.video-editing-music,
span.video-editing-insert {
  position: relative;
}

.editor-video-image img,
.editor-video-video video {
  width: 100%;
  height: 100%;
}

#video-editor-content .editor-video-video video {
  border-radius: 0px;
}

/* .editor-text-box form {
  width: 98%;
  padding: 1%;
  height: 96%;
  background: transparent;
} */
/*  */
.editor-text-box form {
  width: 100%;
  padding: 0px;
  height: 100%;
  /* background: transparent; */
}

.editor-text-box form textarea {
  width: 100%;
  padding: 0px;
  height: 100%;
}

/*  */

.editor-text-box #textarea {
  width: 100%;
  height: 100%;
  border: 0px;
  resize: none;
  background: transparent;
  font-size: 14px;
  padding: 0px;
  color: #000;
  text-align: start;
}

.editor-text-box input[type="submit"] {
  display: none;
}

.editor-text-box #textarea:focus-visible {
  outline: none !important;
}

.editor-text-box {
  background: rgb(255, 255, 255);
}

.editor-text-box,
.editor-video-image,
.editor-video-video,
.editor-blur-box {
  z-index: 11;
}

.editor-blur-box-inner {
  -webkit-backdrop-filter: blur(9px);
  -moz-backdrop-filter: blur(9px);
  -o-backdrop-filter: blur(9px);
  -ms-backdrop-filter: blur(9px);
  backdrop-filter: blur(9px);
  width: 100%;
  height: 100%;
}

span.box-timer-close {
  position: absolute;
  top: -7px;
  left: 0px;
  width: 12px;
  height: 12px;
  cursor: pointer;
}

.box-timer-close img {
  width: 100%;
}

div#quix-dashboard-message {
  position: fixed;
  top: 20%;
  width: 100%;
  text-align: center;
  font-size: 30px;
  z-index: 12;
  display: none;
}

#quix-dashboard-message img {
  max-width: 250px;
}

#quix-dashboard-message span {
  width: 100%;
  float: left;
}

span.loader__dot {
  width: auto !important;
  float: none !important;
}

@keyframes blink {
  50% {
    color: transparent;
  }
}

.loader__dot {
  animation: 1s blink infinite;
}

.loader__dot:nth-child(2) {
  animation-delay: 250ms;
}

.loader__dot:nth-child(3) {
  animation-delay: 500ms;
}

div.quix-dashboard-popup-inner {
  position: relative;
  padding-bottom: 20px;
}

.quix-dashboard-popup-inner .quix-dashboard-close img {
  width: 30px;
}

.quix-dashboard-popup-inner .quix-dashboard-close {
  position: absolute;
  right: -12px;
  top: -12px;
  cursor: pointer;
}

.quix-dashboard-popup-body {
  margin-bottom: 10px;
}

div#video-editor-content-right {
  float: left;
  width: 20%;
}

div#video-editor-content-outer {
  float: left;
  width: 80%;
}

div#video-editor-editing-options {
  float: left;
  width: 100%;
  height: 36px;
  padding: 4px 0px;
  display: none;
}

div#video-editor-editing-options-inner {
  padding: 0px 10px;
  height: 100%;
}

.fields-row {
  float: left;
  padding: 5px 5px;
  position: relative;
  font-size: 13px;
}

.fields-row.font-bold,
.fields-row.font-italic,
.fields-row.font-align {
  padding: 2px 0px;
}

.fields-row input[type="checkbox"],
.fields-row input[type="radio"] {
  opacity: 0;
  position: absolute;
  left: 0;
  top: 0;
  cursor: pointer;
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
}

.fields-row img {
  width: 22px;
  cursor: pointer;
  padding: 3px;
  margin: 0px 2px;
  border-radius: 50px;
}

.fields-row.active img {
  background: #403f3f;
}

.fields-col.active img {
  background: #403f3f;
}

.fields-row.font-align span {
  position: relative;
  border-radius: 25px;
}

.fields-row.font-color span {
  cursor: pointer;
  height: 18px;
  width: 18px;
  margin: 0px 2px;
  float: left;
  position: relative;
  border: 1px solid transparent;
}

.fields-row.font-color span.active {
  border: 1px solid #fff;
}

span.fields-row.apply-button {
  margin-top: 1px;
}

.fields-row.apply-button span {
  background: #fff;
  padding: 5px 15px;
  border-radius: 5px;
  color: #000;
  cursor: pointer;
}

.int-select-box.crop {
  background: red;
}

.video-progress-scale {
  float: left;
  width: 100%;
  position: relative;
  top: -1px;
}

.mark-ms {
  float: left;
  font-size: 12px;
  /*border-right: 1px solid #FFF;*/
  height: 4px;
  margin-top: 6px;
}

.mark-half-sec {
  float: left;
  font-size: 10px;
  border-left: 1px solid #9c9696;
  height: 6px;
  margin-top: 4px;
}

.mark-sec {
  float: left;
  font-size: 10px;
  border-left: 1px solid #9c9696;
  height: 10px;
}

.int-select-box,
.editor-video-box {
  cursor: pointer;
}

.int-select-box.active {
  border: 2px solid #cf1660 !important;
}

.editor-video-box.active {
  border: 2px solid #cf1660 !important;
}

div#video-annotation-blocks {
  float: left;
  width: 100%;
}

div#video-annotation-blocks-inner {
  float: left;
  width: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  height: 170px;
  position: relative;
}

.ui-resizable-w {
  left: -5px;
  width: 7px;
  top: 0;
  height: 100%;
  cursor: w-resize;
}

.box-timer-left,
.box-timer-right {
  display: none;
}

span.box-timer-left {
  position: absolute;
  background: #000000bd;
  padding: 2px;
  top: 20px;
  color: #fff;
  left: 0px;
  border: 1px solid #000;
  z-index: 16;
  width: auto;
  font-size: 10px;
}

span.box-timer-right {
  position: absolute;
  background: #000000bd;
  padding: 2px;
  top: 20px;
  color: #fff;
  right: 0px;
  border: 1px solid #000;
  z-index: 16;
  width: auto;
  font-size: 10px;
}

span.box-title {
  width: auto;
  padding-left: 10px;
  float: none;
}

.int-select-box-inner {
  text-align: center;
  line-height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  /* flex-direction: column; */
}

/* Video Editor Page */
.quix-tab {
  float: left;
  padding: 10px 20px;
  cursor: pointer;
  text-decoration: none;
  color: #fff;
}

.quix-tab:hover {
  color: #fff;
}

.quix-tab-images {
  border-right: 1px solid #fff;
}

.quix-dashboard-content-tabs-inner {
  float: left;
  background: #000;
  color: #fff;
  border-top: 1px solid #000;
  border-left: 1px solid #000;
  border-right: 1px solid #000;
}

div#quix-dashboard-content-tabs {
  float: left;
  margin-top: 15px;
  background: #fff;
  z-index: 1;
  position: relative;
}

div#quix-dashboard-content-tabs-content {
  float: left;
  width: 100%;
  border: 1px solid #000000;
  padding: 1% 1%;
  position: relative;
  top: -1px;
  z-index: unset;
}

.fields-row.font-size {
  display: flex;
  align-items: center;
}

.fields-row.font-size label {
  max-width: max-content;
  width: 100%;
  margin-right: 20px;
}

/* .quix-dashboard-content-videos {
  display: none;
} */
.quix-tab.active {
  background: #fff;
  color: #000;
}

.quix-tab.active:hover {
  color: #000;
}

.social_icon_copy_right .Copyright,
.social_icon_copy_right .Quixy_Logo {
  text-align: center;
  width: 100%;
  display: flex;
  line-height: 30px;
  justify-content: center;
  align-items: center;
  gap: 5px;
}

.Quixy_Logo img {
  position: relative;
  /* top: 5px; */
}

span.Quixy_Logo img {
  width: auto;
  height: 26px;
}

.social_icon_copy_right span {
  vertical-align: middle;
}

.quixy-dashboard-footer-container {
  margin: 0px auto;
  width: 100%;
  padding-right: var(--bs-gutter-x, 5rem);
  padding-left: var(--bs-gutter-x, 5rem);
}

hr:not([size]) {
  height: 1px;
  margin-bottom: 0;
}

.sitetitle {
  width: auto;
  margin-bottom: 10px;
  height: 46px;
}

.login-page {
  background: #fff;
  height: 100%;
  width: 100%;
}

.login-logo-outer,
.login-logo-inner {
  float: left;
  width: 100%;
  padding: 10px;
}

.login-logo-inner img {
  width: auto;
  height: 46px;
}

div#quix-important-message {
  background: #fff;
  color: #000;
  text-align: center;
  color: #d16363;
  font-size: 13px;
  padding: 10px;
  font-weight: 600;
}

#quix-dashboard-popup .send-link-share {
  margin: 0px 4px;
}

#quix-dashboard-popup .quix-email-share-heading {
  margin-bottom: 15px;
}

#quix-dashboard-popup .quix-email-share-form {
  margin-top: 8px;
}

.quix-email-share-form .send-link-share,
.quix-email-share-form .close-link-share {
  background: #000000;
  padding: 6px 10px;
  border-radius: 7px;
  cursor: pointer;
  border: none;
  color: #ffffff;
}

#quix-dashboard-popup .email-share-heading span {
  font-size: 18px;
  width: 100%;
  float: left;
  margin-top: 10px;
}

#quix-dashboard-popup .quix-social-share-form {
  margin: 20px 0px 16px 0px;
  text-align: center;
  display: flex;
  /* justify-content: center; */
  align-items: center;
  flex-wrap: wrap;
  gap: 16px 10px;
  padding: 0px 26px;
}

#quix-dashboard-popup .quix-social-share-form div.share-on-media {
  display: inline-block;
  width: 35px;
  margin: 0px 10px;
}

#quix-dashboard-popup .quix-social-share-form div.share-on-media {
  display: inline-block;
  width: 35px;
  margin: 0px 10px;
}

#quix-dashboard-popup .quix-social-share-form div img {
  width: 100%;
  cursor: pointer;
}

.loader-icon {
  width: 30px;
  height: 30px;
  float: left;
  display: none;
  right: 32%;
  top: 26px;
  position: absolute;
}

/*  */
.quix-dashboard-content-actions-inner {
  display: flex;
}

.quix-search-box input.form-control {
  border: 1px solid #000;
}

.quix-search-box button.quix-search-btn,
.quix-search-box button.quix-search-cancelbtn {
  border: 1px solid #000;
  color: #fff;
  background-color: #000;
  border-radius: 0px;
  cursor: pointer;
  padding: 6px 26px;
  margin-right: 8px;
  font-size: 14px;
}

.sg-custom-drop-menu {
  position: relative;
  margin: 0px 5px;
}

.sg-custom-drop-title {
  background-color: #000;
  padding: 6px 8px;
  color: #ffff;
  font-size: 13px;
  font-weight: 400;
  width: max-content;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.sg-custom-drop-title span {
  padding: 4px 5px 2px 5px;
}

.sg-custom-drop-title img#sg-drop-icon {
  height: 12px;
  margin-left: 6px;
}

.sg-drop-menu-options {
  position: absolute;
  top: 100%;
  left: 0;
  background-color: #000;
  margin-top: 1px;
  z-index: 2222;
  min-width: 100%;
  width: max-content;
}

.sg-drop-menu-options li.nav-items {
  font-size: 13px;
  color: #fff;
  cursor: pointer !important;
  padding: 2px 8px;
  width: 100%;
}

.sg-drop-menu-options li.nav-items:hover {
  background-color: #cfcbcb62;
}

.quix-column-user-details {
  width: 100%;
}

/* pricing popup css */
div#quix-dashboard-popup.quix-pricing-popup {
  position: absolute;
  top: 4%;
}

.quix-pricing-popup div.quix-dashboard-popup-inner {
  width: 1100px;
}

.quix-make-payment-popup div.quix-dashboard-popup-inner {
  width: 600px;
}

.quix-pricing-plans-outer {
  padding: 0 40px;
}

.quix-user-edit-popup div.quix-dashboard-popup-inner {
  width: 450px;
}

.quix-user-profile {
  margin: 0 auto;
  width: 150px;
  padding: 10px;
  position: relative;
}

.quix-user-profile-box {
  width: 120px;
  height: 120px;
  border-radius: 50%;
  overflow: hidden;
  background-color: #000;
  position: relative;
  cursor: pointer;
}

.quix-user-profile-box img.quix-profile-img {
  height: 100%;
  width: 100%;
  background-size: cover;
  object-position: center;
  object-fit: cover;
}

.quix-user-profile .quix-edit-profile-option {
  height: 30px;
  width: 30px;
  position: absolute;
  right: 28px;
  bottom: 20px;
  border: 1px solid #000;
  border-radius: 50%;
  overflow: hidden;
}

.quix-edit-profile-option img.quix-edit-icon {
  height: 100%;
  width: 100%;
}

.quix-choose-profile {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  cursor: pointer;
  opacity: 0;
  z-index: 11;
}

.quix-profile-user-detail p {
  margin: 0;
}

.quix-profile-user-detail p.quix-user-name {
  font-size: 16px;
  font-weight: 600;
  margin-top: 10px;
  margin-bottom: 6px;
  max-height: 80px;
  overflow: hidden;
}

.quix-profile-user-detail p.quix-edit-user {
  color: #0565ff;
  font-weight: 600;
  font-size: 14px;
  cursor: pointer;
}

.quix-user-edit-details {
  padding: 0px 30px;
}

.quix-form .quix-form-filed {
  position: relative;
}

.quix-form .quix-form-filed input {
  border: 1px solid #000;
  border-radius: 6px;
  width: 100%;
  padding-left: 40px;
  font-size: 14px;
  height: 40px;
}

.quix-form .quix-form-filed .quix-edit-name,
.quix-form .quix-form-filed .quix-edit-email,
.quix-form .quix-form-filed .quix-edit-password {
  position: relative;
}

.quix-form .quix-form-filed .quix-edit-name::after,
.quix-form .quix-form-filed .quix-edit-email::after,
.quix-form .quix-form-filed .quix-edit-password::after {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  height: 36px;
  width: 36px;
  background-repeat: no-repeat;
  background-position: 10px center;
}

.quix-form .quix-form-filed .quix-edit-name::after {
  background-image: url("../images/user-icon.png");
}

.quix-form .quix-form-filed .quix-edit-email::after {
  background-image: url("../images/email-icon.png");
  top: 3px;
}

.quix-form .quix-form-filed .quix-edit-password::after {
  background-image: url("../images/password-icon.png");
}

/*  */
/* pricing Plan */
.quix-membership-plans-list {
  margin-top: 30px;
  display: grid;
  gap: 1rem;
  grid-template-columns: repeat(auto-fill, minmax(260px, 1fr));
}

.quix-membership-card label.btn {
  width: 100%;
  background-color: #fff;
  box-shadow: 0px 3px 5px 1px rgba(0, 0, 0, 0.2);
  border: 2px solid transparent;
  text-align: center;
  position: relative;
  min-height: 150px;
  padding: 16px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: calc(100% - 0px);
  transition: 0.3s;
}

.quix-selected-icon {
  position: absolute;
  top: 0;
  left: 0;
  margin: 6px;
  width: 24px;
  display: none;
}

.quix-membership-card span.quix-plan-price {
  color: #000;
  font-size: 18px;
  font-weight: 600;
  line-height: 26px;
}

.quix-membership-card span.quix-plan-description {
  font-size: 13px;
  padding: 10px 0px;
}

.quix-membership-card label.btn:hover {
  border: 2px solid #000;
}

.quix-membership-card .quix-plan-features div {
  font-size: 13px;
  width: 100%;
  float: left;
  margin: 3px 0px;
  font-weight: 700;
}

.quix-membership-card .quix-plan-feature-row img.quix-tick-icon {
  width: 20px;
}

.quix-membership-card .quix-plan-feature-row span {
  position: relative;
  top: 1px;
  left: 10px;
}

.quix-membership-card label.btn.active-membership {
  border: 2px solid #16721e;
}

.quix-membership-card label.btn.active-membership>.quix-selected-icon {
  display: block;
}

.quix-membership-card label.btn:focus,
.quix-membership-card input.btn-check:focus {
  box-shadow: 0px 3px 5px 1px rgba(0, 0, 0, 0.2);
  display: none;
}

.quix-subscribe-btn.btn {
  background-color: #000;
  padding: 4px 10px;
  color: #fff;
  font-size: 13px;
  font-weight: 500;
  margin: 10px auto 0;
  width: 100px;
}

.btn.quix-subscribe-btn.quix-subscribe-btn-disabled {
  opacity: 0.6;
}

.quix-subscribe-btn.btn:focus,
.edit-user-detail-btn.btn:focus {
  box-shadow: none;
}

.StripeElement {
  width: 300px;
  margin: 0px auto 30px auto;
}

.active-membership .quix-subscribe-btn {
  background: #16721e;
  color: #fff;
  font-weight: 800;
  border: 2px solid #16721e;
}

/* pricing Plan */
#feedback-share-popup-wrapper,
#success-popup-wrapper,
#failure-popup-wrapper {
  width: 100%;
  background: #00000085;
  height: 100%;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 1111;
}

.email-share-popup,
.feedback-share-popup,
.success-popup,
.failure-popup {
  width: 350px;
  height: 300px;
  margin: 0px auto;
  position: relative;
  top: 15%;
  background: #ffffff;
  padding: 20px;
  border-radius: 10px;
  color: #000000;
}

.feedback-share-heading span,
.success-heading span,
.failure-heading span {
  font-size: 18px;
  width: 100%;
  float: left;
  margin-top: 10px;
  font-weight: 400;
}

.success-popup .success-description,
.failure-popup .failure-description {
  font-size: 16px;
}

.success-popup,
.failure-popup {
  height: 330px;
}

.success-heading img,
.failure-heading img {
  width: 45%;
}

.email-share-heading,
.feedback-share-heading,
.success-heading,
.failure-heading {
  float: left;
  width: 100%;
  text-align: center;
  margin-bottom: 15px;
}

/* Storage card Css */
.quix-available-storage-card {
  background-color: rgba(5, 101, 255, 0.1);
  padding: 16px 20px;
  border-radius: 8px;
  border: 1px solid #0565ff;
  margin: 10px 20px;
  position: relative;
}

.quix-available-storage-card span.quix-storage-card-heading {
  font-size: 14px;
  font-weight: 600;
  color: #000;
}

.quix-available-storage-card .quix-storage-card-description p {
  font-size: 14px;
  color: #000;
  margin: 0;
  display: inline-flex;
  margin-right: 16px;
}

.quix-available-storage-card .quix-storage-card-upgrade-btn {
  padding: 6px 12px;
  border-radius: 8px;
  border: 1px solid #0565ff;
  background-color: #0565ff;
  color: #ffff;
  opacity: 0.6;
}

.quix-available-storage-card .quix-storage-card-progress {
  display: flex;
  align-items: center;
  height: 40px;
}

.quix-available-storage-card .quix-storage-progress-outer {
  background-color: #ffffff;
  border-radius: 20px;
  height: 12px;
  width: 40%;
  overflow: hidden;
}

.quix-available-storage-card .quix-storage-progress-outer .quix-storage-progress {
  height: 100%;
  width: 0%;
  background-color: #22c33a;
  border-radius: 20px;
  transition: 0.3s;
}

.quix-available-storage-card .quix-storage-detail,
.quix-available-storage-card .quix-storage-detail span {
  margin: 0 6px;
  font-size: 14px;
}

.quix-available-storage-card .quix-storage-modal-close {
  position: absolute;
  right: 16px;
  top: 16px;
  cursor: pointer;
}

.quix-available-storage-card .quix-storage-modal-close img {
  width: 20px;
}

/* hide-upgrade */
.quix-available-storage-card.hide-upgrade {
  display: flex;
  align-items: center;
  transition: all 5s;
}

.quix-available-storage-card.hide-upgrade .quix-storage-card-description {
  display: none;
}

.quix-available-storage-card.hide-upgrade .quix-storage-card-progress {
  height: unset;
}

.resize-storage {
  position: absolute;
  right: 20px;
  top: 15px;
}

.resize-storage img {
  width: 20px;
}

.quix-available-storage-card.hide-upgrade .resize-storage img {
  rotate: 180deg;
}

/* hide-upgrade */

.quix-dashboard-notification {
  opacity: 0.6;
}

.quix-dashboard-user-profile-links-row.help-row,
.quix-dashboard-user-profile-links-row.pricing-row,
.quix-dashboard-user-profile-links-row.setting-row {
  opacity: 0.6;
}

.quix-data-loading {
  width: 100%;
  text-align: center;
}

.px-4.no-records {
  width: 100%;
  text-align: center;
  font-weight: 700;
  padding-bottom: 15px;
}

/* Video Effects Css */
.quix-video-effect-wrapper {
  display: none;
}

.quix-video-effect-wrapper.quix-video-effect-active {
  display: unset;
}

.quix-video-effect .quix-video-effect-header {
  position: relative;
  padding: 3%;
  border-bottom: 0.3px solid #57575778;
  margin-bottom: 10px;
  text-align: start;
}

.quix-video-effect .quix-video-effect-header span {
  font-size: 16px;
  font-weight: 600;
  color: #fff;
}

.qiux-close-effect {
  width: 20px;
  position: absolute;
  top: 16px;
  right: 16px;
  cursor: pointer;
}

.quix-effect-title {
  font-size: 14px;
  font-weight: 600;
  color: #fff;
}

.quix-video-effect {
  width: 380px;
  background-color: #1f1f1f;
  border-radius: 8px;
  box-shadow: 0px 0px 1px 1px #57575778;
}

.quix-video-effects-column {
  padding: 6px 2%;
  text-align: start;
}

.quix-effect-card-outer {
  display: grid;
  gap: 8px 5px;
  grid-template-columns: repeat(auto-fill, minmax(170px, 1fr));
  margin: 10px 0;
}

.quix-video-effect .quix-video-effects-column-outer {
  height: 460px;
  overflow: auto;
}

.quix-video-effect .quix-video-effects-column-outer::-webkit-scrollbar {
  width: 4px;
}

.quix-video-effect .quix-video-effects-column-outer::-webkit-scrollbar-thumb {
  background-color: darkgrey;
  border-radius: 20px;
}

.quix-effect-card {
  width: 100%;
  min-width: 130px;
  max-width: 170px;
  text-align: center;
  margin-bottom: 6px;
}

.quix-effect-card .quix-effect-card-effect {
  height: 120px;
  width: 100%;
  border: 1px solid #0000;
  cursor: pointer;
  border-radius: 11px;
  overflow: hidden;
  transition: 0.3s;
}

.quix-effect-card-effect:hover {
  border: 1px solid #0565ff;
}

.quix-effect-card-effect.active-effect {
  border: 1px solid #0565ff;
}

.quix-effect-card .quix-effect-card-effect img {
  height: 100%;
  width: 100%;
}

.quix-effect-card span {
  color: #fff;
  font-size: 14px;
}

/* function popup */
.quix-editor-function-popup-wrapper {
  position: absolute;
  top: 30%;
  left: 50%;
  display: none;
  z-index: 11111;
  transform: translate(-50%, -50%);
}

.quix-editor-function-popup-wrapper.quix-active-function-popup {
  display: unset;
}

.quix-editor-function-popup {
  width: 350px;
  background-color: #ffff;
  border-radius: 8px;
  box-shadow: 0px 0px 1px 1px #57575778;
}

.quix-editor-function-popup-header {
  position: relative;
  padding: 6%;
  padding-bottom: 10px;
  margin-bottom: 10px;
  text-align: start;
}

.quix-editor-function-popup-header span {
  font-size: 16px;
  font-weight: 600;
  color: #000;
}

.qiux-close-function-popup {
  width: 20px;
  position: absolute;
  top: 16px;
  right: 16px;
  cursor: pointer;
}

/* voice over */
.quix-voice-over-content {
  padding: 6%;
  padding-top: 0;
}

.quix-voice-over-details {
  display: flex;
  text-align: start;
  align-items: center;
}

.quix-voice-over-content .quix-voice-over-img {
  height: 46px;
  width: 46px;
  cursor: pointer;
}

.quix-voice-over-recording-box {
  margin-left: 20px;
}

.quix-voice-over-recording-box span#quix-voice-over-timer {
  font-size: 20px;
  font-weight: 600;
  color: #000;
}

.quix-voice-over-recording-box p {
  font-size: 14px;
  color: #000;
  font-weight: 600;
  margin: 0;
}

.quix-voice-waves {
  display: flex;
  /* padding: 16px 0; */
  padding-top: 16px;
}

.quix-voice-waves div.quix-rec-wave {
  height: 26px;
  width: 5px;
  background-color: #eeeded;
  margin: 0 2px;
  border-radius: 6px;
  transition: 0.3s;
}

.quix-voice-waves div.quix-rec-wave.quix-active-wave {
  background-color: #0565ff;
}

/* .quix-voice-input-device-content{
  display: none;
} */
.quix-voice-input-device {
  color: #000;
  font-size: 16px;
  text-align: start;
  padding: 12px 0;
  border-top: 2px solid #eeeded;
  margin-top: 16px;
}

.quix-voice-input-device select {
  width: 100%;
  color: #000;
  padding: 6px 4px;
  border-radius: 6px;
  border: 1px solid #c7c7c8;
  margin-top: 6px;
}

.quix-voice-input-device select:focus-visible {
  border: 1px solid #c7c7c8;
}

.quix-voice-rage-box {
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #000;
  text-align: start;
}

.quix-voice-volume {
  color: #000;
  text-align: start;
  display: none;
}

.quix-valume-input {
  width: 60px;
}

.quix-voice-rage-box .quix-voice-rage-outer {
  width: 200px;
}

.quix-voice-rage-box .quix-voice-rage-outer input {
  width: 100%;
  line-height: 100%;
}

.quix-valume-input input {
  width: 100%;
  border-radius: 6px;
  border: 1px solid #c7c7c8;
  padding: 6px 4px;
}

/* voice  popup player */
.quix-voice-footer {
  display: none;
  justify-content: center;
  position: relative;
}

.quix-voice-footer button {
  margin: 0px 6px;
  padding: 5px 30px;
  border: 1px solid #000;
  font-size: 13px;
  color: #fff;
  background: #000;
  border-radius: 4px;
}

.quix-voice-footer button.quix-cancel-voice {
  color: #000;
  background: #fff;
}

.quix-voice-pay-box {
  /* display: none; */
  position: absolute;
  left: 0;
  top: 0;
}

.quix-voice-pay-box .quix-audio-player {
  background-color: #000000;
  text-align: justify;
  padding: 6px 6px;
  display: flex;
}

#quix-pause-voice {
  display: none;
}

.quix-voice-pay-box img {
  width: 26px;
}

/* quix-text-speech-details */
.quix-text-speech-details {
  padding: 6%;
  padding-top: 0;
}

.quix-text-speech-details .quix-text-speech-input-box {
  font-size: 14px;
  font-weight: 400;
  color: #000;
  text-align: start;
}

.quix-text-speech-details .quix-text-speech-input-box textarea#quix-text-speech {
  min-width: 100%;
  max-width: 100%;
  display: block;
  color: #000;
  padding: 6px 4px;
  border-radius: 6px;
  border: 1px solid #c7c7c8;
  margin-top: 6px;
}

.quix-text-speech-details button#convert-btn-textspeech {
  padding: 5px 26px;
  /* border: 1px solid #0565FF; */
  /* background-color: #0565FF; */
  border: 1px solid #000;
  background-color: #000;
  color: #ffff;
  border-radius: 6px;
  margin-top: 16px;
}

.quix-text-speech-title {
  font-size: 14px;
  color: #000;
  text-align: start;
  display: block;
  margin-top: 10px;
}

.text-speech-charater {
  height: 46px;
  width: 46px;
  display: inline-flex;
  margin: 6px;
  justify-content: left;
  cursor: pointer;
  border: 2px solid transparent;
  border-radius: 50%;
  transition: 0.3s;
}

.text-speech-charater img {
  height: 100%;
  width: 100%;
}

.text-speech-charater.active-voice,
.text-speech-charater:hover {
  border: 2px solid #000;
}

.quix-text-to-voice-footer {
  margin-top: 18px;
}

.checked-edit-options {
  display: flex;
  justify-content: end;
  gap: 14px;
}

/* video text conversion */
.video-text-conversion {
  display: flex;
  gap: 8px;
  justify-content: end;
  margin: 2px 0;
}

.video-text-conversion label {
  color: #fff;
  font-size: 14px;
  cursor: pointer;
}

/* media streaming css */
#mediaDevicesDropdown {
  margin-bottom: 10px;
}

.frequency-bars {
  display: flex;
  height: 200px;
  margin-top: 20px;
}

.bar {
  width: 10px;
  margin: 0 2px;
  background-color: #3498db;
  transition: height 0.1s;
}

/* quix-audio-player */
.quix-audio-player img {
  height: 20px;
  width: 20px;
  margin: 0 2px;
}

.quix-audio-player img#quix-pause-audio {
  display: none;
}

/* uninstall page */
.form-row {
  display: flex;
  flex-wrap: wrap;
}

.form-row>.col,
.form-row>[class*="col-"] {
  padding-right: 5px;
  padding-left: 5px;
}

/* loading page */
.quix-loading-wrapper {
  height: 100%;
  width: 100%;
  /* background: rgba(0, 0, 0, 0.87); */
  background: #000;
  position: fixed;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 1111;
}

.quix-loading-page {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

/* quixy-upload-details */
.quix-item-uploader-box .quix-upoloaded-img {
  width: 100px;
  border: 1px solid #898383;
  border-radius: 5px;
  height: 100px;
  position: relative;
}

.quix-item-uploader-box .quix-upoloaded-img img {
  height: 100%;
  width: 100%;
  object-fit: cover;
}

.quix-item-uploader {
  padding: 8px;
  height: 100px;
  width: 100%;
  background-color: #0d6efd0f;
  border: 1px dashed #000;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

.quix-upload-input {
  position: absolute;
  height: 100%;
  width: 100%;
  opacity: 0;
  cursor: pointer;
}

.quix-item-uploader img {
  height: 50px;
  width: auto;
}

.quix-upload-outer {
  padding: 0 50px;
  text-align: start;
}

.quix-upload-outer input.form-control,
.quix-upload-outer textarea.form-control {
  width: 100%;
}

.form-control.form-range:focus {
  background-color: unset;
}

.form-action-button {
  border-radius: 7px;
  cursor: pointer;
  padding: 6px 26px;
  margin-right: 12px;
  font-size: 14px;
}

.form-cancel-button.btn {
  background: #ffffff;
  border: 1px solid #000;
  color: #000;
}

.form-save-button.btn {
  background: #000;
  border: 1px solid #000;
  color: #fff;
}

/* tags Select option */
.edit-tag-outer .tags-item-outer #tags input {
  border: none;
}

.tags-item-outer .searchWrapper {
  border: 1px solid #898383;
  max-height: 200px;
  overflow: auto;
}

.edit-tag-outer .tags-item-outer #tags .chip,
.edit-tag-outer .tags-item-outer #tags .highlightOption,
.edit-tag-outer .tags-item-outer #tags .option:hover {
  background-color: #000;
  color: #fff;
  word-break: break-all;
  white-space: pre-wrap;
}

.quix-add-cutom-tags {
  display: flex;
}

.quix-upload-outer .quix-add-cutom-tags input.form-control {
  width: 72%;
}

.feedback-img {
  width: 300px;
  margin: 0px auto 30px;
}

.add-custom-tag-btn {
  padding: 0px 4%;
  color: #fff;
  margin-left: auto;
  font-size: 13px;
  background: #000;
}

.add-custom-tag-btn:hover {
  background-color: #000;
  color: #fff;
}

.upload-btn-outer img.btn-loader-icons {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

/* quix-file-uploader */
#quix-file-uploader #quix-dashboard-popup {
  position: absolute;
}

.qr-code-popup div.quix-dashboard-popup-inner {
  height: 275px;
}

.qr-code-popup .quix-dashboard-popup-body {
  margin-top: 25px;
}

/* water mark uploader */
/* tabs start*/
.watermakr-tabs-btn-outer {
  width: 100%;
  display: flex;
}

.watermakr-tabs-btn-outer button.btn.watermark-tab-btn {
  border: 1px solid #000;
  background-color: #000;
  color: #fff;
  font-size: 14px;
  padding: 6px 26px;
  width: inherit;
  box-shadow: none;
  border-radius: 0;
}

.watermakr-tabs-btn-outer button.btn.watermark-tab-btn.active {
  background-color: #fff;
  color: #000;
  border-bottom: 0px;
}

.watermark-tab-content {
  display: none;
}

.watermark-tab-content.active {
  display: block;
  border: 1px solid #000;
  border-top: 0px;
  border-bottom: 0px;
}

.water-mark-edit-btn {
  margin: 0 16px;
  border: 1px solid #000;
  border-top: 0;
  padding-bottom: 12px;
}

/* tabs end */
.quix-water-details {
  /* display: grid;
  grid-template-columns: repeat(2, 1fr); */
  padding: 0 16px;
}

.quix-water-screenshot-content,
.quix-water-video-content {
  padding: 0 20px 16px;
}

.quix-water-details .quix-heading-con {
  font-size: 16px;
  margin-bottom: 20px;
}

/* .quix-water-screenshot-content {
  border-right: 1px solid #898383;
} */

.quix-upoloaded-img img {
  width: 100px;
  height: 100px;
  object-fit: cover;
}

.quix-upoloaded-img img#close-uploaded-img {
  width: 26px;
  height: 26px;
  object-fit: cover;
  background-color: #fff;
  border-radius: 50%;
}

.color-picker-box {
  height: 40px;
  padding: 5px;
  border-radius: 5px;
  border: 1px solid #898383;
}

.water-mark-popup div#quix-dashboard-popup {
  position: absolute;
}

.water-mark-popup div.quix-dashboard-popup-inner {
  /* width: 800px; */
  width: 400px;
}

.quix-water-details select.form-control {
  padding: 5px 10px;
  border-radius: 5px;
  border: 1px solid #898383;
  margin: 2px 0px;
}

/*  */
#quix-insert-details-popup {
  width: 500px;
  background: #ffff;
  color: #000;
  z-index: 111;
  top: 36%;
  position: absolute;
  left: 50%;
  transform: translate(-50%, -50%);
  display: none;
  box-shadow: 0px 0px 10px #000000;
  z-index: 222;
}

.quix-insert-form {
  position: relative;
  padding: 20px;
}

.quix-insert-close {
  position: absolute;
  right: -10px;
  top: -10px;
  width: 36px;
  cursor: pointer;
}

.quix-insert-close img {
  width: 100%;
}

.quix-insert-content {
  padding: 20px 20px 0px 20px;
}

.insert-heading {
  font-size: 20px;
  text-align: center;
}

.quix-insert-form-fields {
  display: block;
  margin: 10px 0;
  text-align: justify;
}

.quix-insert-form-fields input {
  display: block;
  width: 100%;
  padding: 6px 10px;
  margin-top: 4px;
  border: 1px solid #000;
}

.int-select-box-inner span.insert-time {
  padding: 0px 6px;
  font-weight: 600;
  float: right;
}

.insert-duration-msg {
  font-size: 12px;
}

.quix-insert-video-size {
  text-align: justify;
  margin-top: 10px;
}

.quix-insert-video-size .quix-insert-video-size-innr {
  display: flex;
  gap: 20px;
  justify-content: space-between;
}

.quix-insert-video-size .quix-insert-form-fields {
  display: flex;
  margin: 4px 0px;
}

.quix-insert-video-size .quix-insert-form-fields input {
  width: auto;
  margin: 0 5px;
}

.insert-action-btn {
  text-align: center;
  margin-top: 20px;
}

.quix-insert-btn {
  border: 1px solid #000;
  color: #fff;
  background-color: #000;
  border-radius: 7px;
  cursor: pointer;
  padding: 6px 26px;
  margin-right: 12px;
  font-size: 14px;
}

.quix-insert-cancel-btn {
  border: 1px solid #000;
  color: #000;
  background-color: #fff;
}

/*  */
/* video editor popup css start */
.video-editor-popup {
  position: absolute;
  left: 50%;
  top: 10%;
  z-index: 11;
  width: 420px;
  background-color: #ffff;
  transform: translate(-50%, -20%);
  box-shadow: 0px 2px 8px 0px #000000a1;
  border-radius: 5px;
}

.video-editor-popup-inner {
  padding: 20px;
}

.video-editor-popup-inner .qiux-close-function-popup {
  width: 20px;
  position: absolute;
  top: 0px;
  right: 0px;
  cursor: pointer;
  transform: translate(10px, -10px);
}

.video-editor-popup-header {
  position: relative;
}

.video-editor-popup-header h5 {
  font-size: 20px;
  font-weight: 600;
  margin: 0;
  color: #000;
}

.video-editor-popup-content {
  margin-top: 26px;
}

#water-mark-popup {
  display: none;
  z-index: 222;
}

.manage-water-mark {
  max-width: 300px;
  margin: 20px auto;
}

.water-mark-box {
  color: #000;
  border: 1px solid #000;
  padding: 8px 10px;
  border-radius: 5px;
  margin-bottom: 10px;
  display: flex;
  justify-content: space-between;
  cursor: pointer;
}

.water-mark-box.disabled-btn {
  opacity: 0.5;
  pointer-events: none;
  cursor: not-allowed;
}

.water-mark-box label {
  margin: auto;
  cursor: pointer;
}

.water-mark-settings .range-input-filed {
  position: relative;
}

.water-mark-settings .range-badge {
  color: #ffff;
  font-size: 12px;
  padding: 2px 10px;
  border-radius: 2px;
  width: max-content;
  position: absolute;
  transform: translateX(-50%);
  background-color: rgba(0, 0, 0, 0.8);
  display: none;
  top: 90%;
  /* left: 50%; */
}

.water-mark-settings .range-input-filed input:hover+.range-badge {
  display: block;
}

.water-mark-settings {
  color: #000;
  padding-top: 14px;
  border-top: 1px dashed #dddd;
  display: none;
}

#water-mark-popup .quix-item-uploader-box .quix-upoloaded-img {
  width: 126px;
  margin-right: 6px;
}

.quix-remove-img-watermark {
  position: absolute;
  top: -5px;
  right: -5px;
  height: 26px;
  cursor: pointer;
}

.quix-remove-img-watermark img {
  height: 100%;
  width: 100%;
  border: 1px solid #000;
  border-radius: 50% !important;
}

.get-summary-wrapper {
  padding: 0px 50px;
}

.get-summary {
  width: 100%;
  display: inline-flex;
  margin-top: 10px;
  margin-bottom: 16px;
  position: relative;
}

.get-summary input[type="text"] {
  padding: 6px 10px;
  border: 1px solid #000;
  border-radius: 5px;
  margin: 0;
  margin-right: 5px;
}

.get-summary .loader-icon {
  right: 50px;
  top: 4px;
}

.summary-text {
  border: 1px solid #000;
  border-radius: 5px;
  overflow-y: auto;
  max-height: 150px;
  min-height: 150px;
  margin-bottom: 10px;
  text-align: start;
  padding: 2px 6px;
  color: #000;
}

.get-summary button.get-summary-button,
.copy-summary-url button.copy-summary-button {
  padding: 8px 10px;
  border: 1px solid #000;
  color: #fff;
  background-color: #000;
  width: 140px;
  border-radius: 5px;
}

.highlight-summary-text {
  color: #0000;
}

.highlight-summary-input {
  background-color: red !important;
}

/* custom-login */
/* verif message */
.success-modal-outer .quix-dashboard-popup-inner {
  width: 400px;
  border-radius: 6px;
  padding: 20px 20px;
}

.success-message-img {
  height: 140px;
  width: 140px;
  margin: auto;
}

.text-message-outer {
  margin-top: 20px;
}

.success-modal-btns button.btn {
  padding: 6px 26px;
  font-size: 14px;
  margin-top: 16px;
  color: #fff;
  border: 1px solid #000;
  background-color: #000;
}

/*  */

.custom-login-details .custom-details-form.card {
  border: 1px solid #000;
}

.custom-login-details .custom-details-form.card .card-header {
  background-color: #000;
  color: #fff;
}

.custom-login-details .custom-details-form.card input {
  border: 1px solid #000;
  border-radius: 5px;
  margin-top: 2px;
  margin-bottom: 2px;
}

#term-and-conditions {
  margin-top: 5px;
}

.term-and-conditions-outer label {
  font-size: 14px;
}

.custom-details-form.card input[type="text"],
.custom-details-form.card input[type="password"] {
  width: 100%;
  padding-left: 40px;
}

.custom-details-form.card .quix-edit-email,
.custom-details-form.card .quix-edit-password,
.custom-details-form.card .quix-edit-name {
  position: relative;
}

.custom-details-form.card .quix-edit-email::after,
.custom-details-form.card .quix-edit-password::after,
.custom-details-form.card .quix-edit-name::after {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  height: 36px;
  width: 36px;
  background-repeat: no-repeat;
  background-position: 10px center;
}

.custom-details-form.card .quix-edit-name::after {
  background-image: url("../images/user-icon.png");
}

.custom-details-form.card .quix-edit-email::after {
  background-image: url("../images/email-icon.png");
}

.custom-details-form.card .quix-edit-password::after {
  background-image: url("../images/password-icon.png");
}

.user-password-filed .password-eyes {
  position: relative;
}

.password-eyes img {
  height: 26px;
  width: 26px;
  position: absolute;
  right: 12px;
  top: 6px;
  cursor: pointer;
}

.custom-details-form.card .form-btn button.btn {
  border: 1px solid #000;
  border-radius: 5px;
  background-color: #000;
  color: #fff;
  padding: 8px 36px;
  font-size: 13px;
}

p.account-dec {
  font-size: 13px;
  margin-top: 12px;
}

.login-devider {
  position: relative;
}

.more-option {
  width: 24px;
  height: 24px;
  background-color: #000;
  border-radius: 50%;
  color: #fff;
  padding: 2px 5px;
  position: absolute;
  transform: translate(-50%, -50%);
  left: 50%;
  top: 50%;
  line-height: 100%;
}

.more-option span {
  font-size: 13px;
}

.loading-btn-img {
  width: 26px;
  height: 26px;
  margin-left: 10px;
}

.quix-form-filed .btn {
  font-size: 13px;
  margin-right: 12px;
  padding: 8px 36px;
  border: 1px solid #000;
}

.quix-cancel-edit.btn {
  background-color: #fff;
  color: #000;
  font-weight: 600;
}

.edit-user-detail-btn.btn {
  background-color: #000;
  color: #ffff;
}

.btn.token-resend-btn {
  border: 1px solid #000;
  border-radius: 5px;
  background-color: #000;
  color: #fff;
  padding: 8px 32px;
  font-size: 13px;
}

.message-box.card {
  box-shadow: 0px 0px 6px 2px #e1e1e191;
  border: 1px solid transparent;
  border-radius: 5px;
  min-height: 200px;
}

.message-box .card-body {
  display: flex;
  text-align: center;
  flex-direction: column;
  gap: 20px;
  padding: 32px 20px;
}

.message-box .card-body img {
  height: 80px;
  width: 80px;
  margin: 0 auto;
}

.message-box .card-body span {
  display: block;
}

.message-box span {
  font-weight: 600;
  margin-left: 10px;
}

/* custom-login */

/* socials-media-platforms */
.quix-dashboard-popup-inner .share-loading {
  height: 100%;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.8);
}

.outer-share-media {
  height: 410px;
  width: 100%;
  overflow: hidden;
}

.quix-dashboard-popup-inner .share-loading img {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.social-share-page {
  padding: 30px;
}

.social-share-page .card-body {
  padding: 1rem 5px;
}

.social-share-page .social-share-main-heading h5,
.social-share-page .title-option h6 {
  font-weight: 600;
}

.socials-media-platforms {
  display: flex;
  flex-wrap: wrap;
  gap: 12px 20px;
}

.socials-media-platforms .share-on-media {
  display: inline-block;
  width: 34px;
  margin: 0px;
  margin-top: 10px;
  position: relative;
}

.socials-media-platforms .share-on-media img {
  width: 100%;
  cursor: pointer;
}

/* user paid feature lock */
.socials-media-platforms .share-on-media span.user-paid-feature {
  position: absolute;
  bottom: 0px;
  right: 0;
  transform: translate(5px, 10px);
}

.social-share-with-link .quix-email-share-form input,
.social-share-with-link .quix-email-share-form button {
  border: 1px solid #000;
  font-size: 13px;
  padding: 3px 10px;
  height: 35px;
  border-radius: 4px;
}

.social-share-with-link .quix-email-share-form button {
  width: 100px;
}

.socials-media-platforms .google-uploader-outer {
  display: flex;
  gap: 12px 16px;
}

/* notion-details-form and slack-share-list */
div.quix-dashboard-popup-inner.notion-details-form,
div.quix-dashboard-popup-inner.slack-share-list,
div.quix-dashboard-popup-inner.onenote-share-list,
div.quix-dashboard-popup-inner.social-share-email,
div.quix-dashboard-popup-inner.social-share-qrcode {
  width: 400px;
  margin: 0 auto;
  box-shadow: 0px 0.43px 3.28px 0.4px #00000026;
}

/* upgrade */
div#quix-dashboard-popup.quix-update-account-wrapper {
  top: 6%;
}

.quix-update-account-wrapper div.quix-dashboard-popup-inner {
  border-radius: 16px;
  overflow: hidden;
  width: 400px;
  box-shadow: 0px 0px 3px 0px #6e6e6ea3;
}

.quix-update-account-wrapper .quix-dashboard-popup-inner .quix-dashboard-close {
  right: 8px;
  top: 8px;
}

div#quix-update-account-inner {
  background: #000;
  padding: 12px 20px;
}

div#quix-update-account-inner .quix-signin-row img {
  height: 150px;
  width: auto;
}

#quix-update-account-inner h3 {
  float: left;
  width: 100%;
  font-size: 15px;
  font-weight: 600;
  color: #fff;
  margin: 12px 0;
}

#quix-update-account-inner span {
  color: #fff;
  font-size: 14px;
}

.quix-signin-button-social>div {
  color: #000;
  background: #fff;
  border-radius: 7px;
  padding: 8px 60px;
  display: flex;
  justify-content: center;
  gap: 8px;
  align-items: center;
  width: max-content;
  margin: 15px auto;
  cursor: pointer;
}

.quix-signin-button-social>div img {
  height: 24px;
  width: 24px;
}

#quix-update-account-inner .quix-settings-footer-inner {
  color: #fff;
}

#quix-update-account-inner .powered_by_logo {
  height: 26px;
  width: auto;
}

/* paid-feature */
.user-paid-feature-outer {
  position: relative;
}

span.user-paid-feature {
  position: absolute;
  bottom: 0px;
  right: 0px;
  transform: translate(12px, 10px);
}

/* share page css  */
.quix-share-page-content {
  float: left;
  width: 100%;
}

.quix-share-page-content-inner {
  display: flex;
  justify-content: center;
  gap: 12px;
  margin: 8px 0;
}

.adsense-sidebar {
  width: calc(300px - 20px);
  height: 100%;
  padding: 20px;
}

.quix-share-content {
  /* background-color: #000; */
  width: calc(100% - 600px);
  max-height: 800px;
}

.quix-share-item {
  width: 100%;
  height: 100%;
}

.quix-share-item video {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.quix-share-item img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

/* adsense css*/
.adsense.float-left {
  display: flex;
  justify-content: center;
}

/* maintenance-mode */
.maintenance-mode-content {
  display: flex;
  align-items: center;
  justify-content: center;
  height: calc(100vh - 100px);
  width: 100%;
  float: left;
  flex-direction: column;
}

.maintenance-mode-content img.maintenance-img {
  width: 280px;
  margin-bottom: 40px;
}

/* responsive */
@media only screen and (max-width: 1140px) {
  .quix-share-page-content-inner {
    flex-direction: column;
    padding: 5%;
  }

  .quix-share-content {
    width: 100%;
  }

  .quix-share-page-content-inner .adsense-sidebar {
    width: 100%;
    max-height: 220px !important;
    padding: 15px;
  }
}

@media only screen and (max-width: 767px) {
  .quix-share-page-content-inner {
    flex-direction: column;
    padding: 3%;
  }

  .quix-share-page-content-inner .adsense-sidebar {
    max-height: 120px !important;
    padding: 10px;
  }
}

.video-editing-blur {
  position: relative;
}

.lock-icon {
  position: absolute;
  bottom: -5px;
  right: 3px;
  font-size: 12px;
  color: #888;
  pointer-events: none;
}

.paid-disabled {
  position: relative;
  opacity: 0.5;
}

.video-text-conversion .lock-icon {
  bottom: -15px;
  right: -6px;
}

.water-mark-box#account-btn .lock-icon {
  top: 58%;
  right: 8%;
  transform: translate(-50%, -50%);
}